export const languages = [
    { code: "ar", name: "Arabic" },
    { code: "fr", name: "French" },
    { code: "es", name: "Spanish" },
    { code: "de", name: "German" },
    { code: "cn", name: "Chinese" },
    { code: "ru", name: "Russian" },
    { code: "tr", name: "Turkish" },
    { code: "fa", name: "Persian" },
    { code: "he", name: "Hebrew" },
    { code: "ja", name: "Japanese" },
    { code: "pt", name: "Portuguese" },
    { code: "it", name: "Italian" },
    { code: "hi", name: "Hindi" },
  ];
  
  export const getLanguages = () => languages;
  
  export const findLanguageByCode = (code) => {
    return languages.find(lang => lang.code === code);
  };