<template>
  <div class="w-100">
    <h1 class="d-inline">Banners</h1>
    <button class="btn btn-success float-right" @click="showCreateModal">Create a banner</button>

    <div class="table-responsive">
      <table class="table mt-4">
        <thead class="thead-light">
        <tr>
          <th scope="col">ID</th>
          <th scope="col">Position</th>
          <th scope="col">Title</th>
          <th scope="col">Type</th>
          <th scope="col">Photo/Video</th>
          <!-- <th scope="col">Description</th> -->
          <th scope="col">Created at</th>
          <th scope="col">Actions</th>
          <th scope="col">Status</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="banner in items" v-bind:key="banner.id">
          <td>{{ banner.id }}</td>
          <td>{{ banner.position }}</td>
          <td>{{ banner.title || '-' }}</td>
          <td>{{ banner.type || '-' }}</td>
          <td v-if="banner.media?.type == 'image'"><img :src="banner.media.url" @click="showPhotoModal(banner.media.url)" class="rounded cursor-pointer" style="height:65px;"></td>
          <td v-if="banner.media?.type == 'video'">
            <video :src="banner.media.url" @click="showVideoModal(banner.media.url)" class="rounded cursor-pointer" style="height:65px;"></video>
          </td>
          <!-- <td>{{ banner.description }}</td> -->
          <td>{{ formatDate(banner.created_at) }}</td>
          <td>
            <button class="btn btn-sm btn-primary" title="Edit" @click="showUpdateModal(banner)">
              <i class="fa fa-edit"></i>
            </button>
            <button class="btn btn-sm btn-danger ml-2" title="Delete" @click="deleteBanner(banner)">
              <i class="fa fa-trash"></i>
            </button>
          </td>
          <td v-html="getStatus(banner.is_available)"></td>
        </tr>
        </tbody>
      </table>
    </div>
    <modal name="step-selection-modal" height="auto">
      <div class="p-4">
        <button @click="$modal.hide('step-selection-modal')" class="modal-close-button">❌</button>
        <div class="h3 mb-3 text-center">Select Banner Type</div>
        <div class="selection-container">
          <div class="selection-item product" @click="selectType('product')">
            <div class="selection-overlay">
              <div class="selection-text">Product Banner</div>
            </div>
          </div>
          <div class="selection-item url" @click="selectType('url')">
            <div class="selection-overlay">
              <div class="selection-text">URL Banner</div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal name="create-modal" height="auto">
      <div class="p-4">
        <button @click="$modal.hide('create-modal')" class="modal-close-button">❌</button>
        <div class="h3 mb-3">Create a banner</div>
        <form action="">
          <div v-if="type === 'product'">
            <div class="my-3">
              <h5 class="d-inline">Products</h5>
              <div slot="header">
                <v-select v-model="createForm.product" @search="fetchProducts" @input="setCreateSelected" :options="options" label="title"
                          :filterable="false" placeholder="Select products">
                  <template slot="no-options">type to search products...</template>
                  <template slot="option" slot-scope="option">
                    <div>{{ option.id + ' - ' + option.title + ' - ' + option.location?.city.name }}</div>
                  </template>
                  <template slot="selected-option" slot-scope="option">
                    <div>{{ option.id + ' - ' + option.title + ' - ' + option.location?.city.name }}</div>
                  </template>
                </v-select>
              </div>
            </div>
          </div>

          <div v-if="type === 'url'">
            <div class="mb-3">
              <h5 class="d-inline">URL</h5>
              <input
                  v-model="createForm.url"
                  type="text"
                  class="form-control"
                  placeholder="Enter URL"
                  required
              />
            </div>

            <div class="mb-3">
              <h5 class="d-inline">Call To Action</h5>
              <input
                  v-model="createForm.call_to_action"
                  type="text"
                  class="form-control"
                  placeholder="Url Title"
                  required
              />
              <div v-for="language of languages" class="input-group">
                <div class="input-group-prepend col-4 col-sm-3 col-md-2 col-lg-2 col-xl-1 px-0">
                  <span class="input-group-text w-100">{{ language.code }}</span>
                </div>
                <input v-model="createForm.translations.call_to_action && createForm.translations.call_to_action[language.code]" class="form-control"
                       :placeholder="[[language.name]] + ' Translation'"/>
              </div>
            </div>

          </div>



          <!-- Common fields for both types -->
          <div class="mb-3">
            <h5 class="d-inline">Title</h5>
            <input
                v-model="createForm.title"
                type="text"
                class="form-control"
                placeholder="Title"
                required
            />
            <div v-for="language of languages" class="input-group">
              <div class="input-group-prepend col-4 col-sm-3 col-md-2 col-lg-2 col-xl-1 px-0">
                <span class="input-group-text w-100">{{ language.code }}</span>
              </div>
              <input v-model="createForm.translations.title && createForm.translations.title[language.code]" class="form-control"
                     :placeholder="[[language.name]] + ' Translation'"/>
            </div>
          </div>

          <div class="mb-3">
            <h5 class="d-inline">Description</h5>
            <input
                v-model="createForm.description"
                type="text"
                class="form-control"
                placeholder="Description, some alternative text"
            />
            <div v-for="language of languages" class="input-group">
              <div class="input-group-prepend col-4 col-sm-3 col-md-2 col-lg-2 col-xl-1 px-0">
                <span class="input-group-text w-100">{{ language.code }}</span>
              </div>
              <input v-model="createForm.translations.description && createForm.translations.description[language.code]" class="form-control"
                     :placeholder="[[language.name]] + ' Translation'"/>
            </div>
          </div>
          <hr>

          <h5>Select Media Type:</h5>
          <input class="mx-2" type="radio" checked="checked" id="image" v-model="selectedMediaTypes" value="image">
          <label for="image">Image</label><br>
          <input class="mx-2" type="radio" id="video" v-model="selectedMediaTypes" value="video">
          <label for="video">Video</label><br>

          <div v-if="selectedMediaTypes.includes('image')">
            <file-upload label="Select a photo..." @done="file_name => this.createForm.photo = file_name" class="mt-3"></file-upload>
          </div>

          <div v-if="selectedMediaTypes.includes('video')">
            <file-upload
              label="Select a video..."
              @done="file_name => this.createForm.video = file_name"
              @coverPhoto="file_name => this.createForm.cover_photo = file_name"
              class="mt-3">
            </file-upload>
          </div>
          <hr>

          <ul style="padding-left: 20px; margin-top: 20px">
            <li v-for="error in createForm.errors" v-bind:key="error" class="text-danger">{{ error }}</li>
          </ul>

          <button type="submit" class="btn btn-primary mt-3 w-100" @click.prevent="createBanner">
            Create
          </button>
        </form>
      </div>
    </modal>


    <modal name="update-modal" height="auto">
      <div class="p-4">
        <button @click="$modal.hide('update-modal')" class="modal-close-button">❌</button>
        <div class="h3 mb-3">Edit a banner</div>
        <form action="">
          <div v-if="updateForm.type === 'product'">
            <div class="my-3">
              <h5 class="d-inline">Products</h5>
              <div slot="header">
                <v-select v-model="updateForm.product" @search="fetchProducts" @input="setUpdateSelected" :options="options" label="title"
                          :filterable="false" placeholder="Select products">
                  <template slot="no-options">type to search products...</template>
                  <template slot="option" slot-scope="option">
                    <div>{{ option.id + ' - ' + option.title + ' - ' + option.location?.city.name }}</div>
                  </template>
                  <template slot="selected-option" slot-scope="option">
                    <div>{{ option.id + ' - ' + option.title + ' - ' + option.location?.city.name }}</div>
                  </template>
                </v-select>
              </div>
            </div>
          </div>

          <div v-if="updateForm.type === 'url'">
            <div class="mb-3">
              <h5 class="d-inline">URL</h5>
              <input
                  v-model="updateForm.url"
                  type="text"
                  class="form-control"
                  placeholder="Enter URL"
                  required
              />
            </div>

            <div class="mb-3">
              <h5 class="d-inline">Url Title</h5>
              <input
                  v-model="updateForm.call_to_action"
                  type="text"
                  class="form-control"
                  placeholder="Url Title"
                  required
              />
              <div v-for="language of languages" class="input-group">
                <div class="input-group-prepend col-4 col-sm-3 col-md-2 col-lg-2 col-xl-1 px-0">
                  <span class="input-group-text w-100">{{ language.code }}</span>
                </div>
                <input v-model="updateForm.translations.call_to_action && updateForm.translations.call_to_action[language.code]" class="form-control"
                       :placeholder="[[language.name]] + ' Translation'"/>
              </div>
            </div>

          </div>

          <!-- Common fields for both types -->
          <div class="mb-3">
            <h5 class="d-inline">Title</h5>
            <input
                v-model="updateForm.title"
                type="text"
                class="form-control"
                placeholder="title"
            >
            <div v-for="language in languages" class="input-group">
              <div class="input-group-prepend col-4 col-sm-3 col-md-2 col-lg-2 col-xl-1 px-0">
                <span class="input-group-text w-100">{{ language.code }}</span>
              </div>
              <input v-model="updateForm.translations.title && updateForm.translations.title[language.code]" class="form-control" id="translationsInput"
                     :placeholder="[[language.name]] + ' Translation'"/>
            </div>
          </div>

          <div class="mb-3">
            <h5 class="d-inline">Description</h5>
            <input
                v-model="updateForm.description"
                type="text"
                class="form-control"
                placeholder="Description, some alternative text"
                required
            />
            <div v-for="language of languages" class="input-group">
              <div class="input-group-prepend col-4 col-sm-3 col-md-2 col-lg-2 col-xl-1 px-0">
                <span class="input-group-text w-100">{{ language.code }}</span>
              </div>
              <input v-model="updateForm.translations.description && updateForm.translations.description[language.code]" class="form-control" id="translationsInput"
                     :placeholder="[[language.name]] + ' Translation'"/>
            </div>
          </div>
          <hr>

          <div v-if="updateForm.mediaType == 'image'">
            <file-upload label="Select a photo..." @done="file_name => this.updateForm.photo = file_name" :photo="updateForm.mediaUrl" class="mt-3"></file-upload>
          </div>

          <div v-if="updateForm.mediaType == 'video'">
            <file-upload
              label="Select a video..."
              @done="file_name => this.updateForm.video = file_name"
              @coverPhoto="file_name => this.updateForm.cover_photo = file_name"
              :video="updateForm.mediaUrl"
              class="mt-3">
            </file-upload>
          </div>
          <!-- <file-upload label="Select a photo..." @done="file_name => this.updateForm.media = file_name" :photo="updateForm.previousPhoto" class="mt-3"></file-upload> -->
          <!-- <file-upload label="Select a video..." @done="file_name => this.updateForm.video = file_name" :video="updateForm.previousVideo" class="mt-3"></file-upload> -->

          <hr>


          <ul style="padding-left: 20px; margin-top: 20px">
            <li
                v-for="error in updateForm.errors"
                v-bind:key="error"
                class="text-danger"
            >
              {{ error }}
            </li>
          </ul>

          <button type="submit" class="btn btn-primary mt-3 w-100" @click.prevent="updateBanner">
            Edit
          </button>
        </form>
      </div>
    </modal>

    <modal name="photo-modal" height="auto" :adaptive="true">
      <div class="p-4">
        <button @click="$modal.hide('photo-modal')" class="modal-close-button">❌</button>
        <div class="h3 mb-3">Banner photo</div>
        <img :src="photoModalCurrentUrl" class="w-100 mt-3">
      </div>
    </modal>

    <modal name="video-modal" height="auto" :adaptive="true">
      <div class="p-4">
        <button @click="$modal.hide('video-modal')" class="modal-close-button">❌</button>
        <div class="h3 mb-3">Banner video</div>
        <video :src="videoModalCurrentUrl" class="w-100 mt-3"></video>
      </div>
    </modal>
  </div>
</template>

<script>
import {config} from "../helpers";
import KeyValueInput from "../components/KeyValueInput.vue";
import FileUpload from '../components/FileUpload.vue';
import VueSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import axios from "axios";
import _ from "lodash";

export default {
  name: "Banners",
  components: {
    'key-value-input': KeyValueInput,
    'file-upload': FileUpload,
    'v-select': VueSelect,
  },
  data() {
    return {
      videoUrl: "", 
      type: null, // to store the selected banner type
      url: "",
      items: [],
      createForm: {
        position: '',
        title:'',
        description: '',
        photo: '',
        video: '',
        meta: {},
        product: {},
        errors: [],
        translations: {
          title: [],
          call_to_action: [],
          description: []
        },
        type: '', // 'product' or 'url'
        url: '', // for URL banners
        call_to_action: '', // for URL banners
      },
      selectedMediaTypes: [],
      imageDetails: '',
      videoDetails: '',
      options: [],
      updateForm: {
        description: '',
        title:'',
        mediaUrl: '',
        mediaType: '',
        photo: '',
        video: '',
        meta: {},
        product: {},
        errors: [],
        translations: {
          title: [],
          call_to_action: [],
          description: []
        },
        type: '', // 'product' or 'url'
        url: '', // for URL banners
        call_to_action: '', // for URL banners
      },
      languages: [
        {code: "ar", name: "Arabic"},
        {code: "fr", name: "French"},
        {code: "es", name: "Spanish"},
        {code: "tr", name: "Turkish"},
        {code: "ru", name: "Russian"},
        {code: "fa", name: "Persian"},
      ],
      selectedLanguage: 'ar',
      photoModalCurrentUrl: '',
      videoModalCurrentUrl: '',
      debouncedFetchProducts: _.debounce((search, loading) => {
        loading(true);
        const params = {};
        params['filter[title]'] = search;
        params['available'] = 1;

        axios.get(config("BACKEND_ROOT") + "/admin/products", {params: params}).then((response) => {
          this.options = response.data.data;
          loading(false);
        });
      }, 350), // Set a delay of 350 milliseconds
    };
  },

  mounted() {
    this.url = config("BACKEND_ROOT") + "/admin/banners";
    this.loadData();
  },

  methods: {
  //   handleVideoUpload(file) {
  //   if (typeof file === "string") {
  //     // If file is a URL string, load it directly
  //     this.createForm.video = file;
  //     this.captureVideoFrameFromURL(file);
  //   } else if (file instanceof File || file instanceof Blob) {
  //     // If file is a Blob or File, create an object URL
  //     this.createForm.video = URL.createObjectURL(file);
  //     this.captureVideoFrameFromURL(this.createForm.video);
  //   } else {
  //     console.error("Unsupported file type:", file);
  //   }
  // },

  // captureVideoFrameFromURL(videoURL) {
  //   console.log('*********************', videoURL)
  //   const videoElement = document.createElement("video");
  //   videoElement.src = "https://ddexperiences-s3.fra1.digitaloceanspaces.com/files/df760db35aa6e266298f2c0f725c84c7.mov"; // Use the correct video source
  //   videoElement.crossOrigin = "anonymous";
  //   videoElement.muted = true; // Avoid autoplay issues

  //   videoElement.addEventListener("loadeddata", () => {
  //     this.captureVideoFrame(videoElement);
  //   });
  // },

  // captureVideoFrame(video) {
  //   const canvas = document.getElementById("coverCanvas");
  //   const ctx = canvas.getContext("2d");

  //   canvas.width = video.videoWidth;
  //   canvas.height = video.videoHeight;

  //   ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);

  //   canvas.toBlob((blob) => {
  //     const formData = new FormData();
  //     formData.append("file", blob, "cover_photo.jpg");

  //     axios
  //       .post(config("BACKEND_ROOT") + "/upload", formData, {
  //         headers: {
  //           "Content-Type": "multipart/form-data",
  //         },
  //       })
  //       .then((response) => {
  //         this.createForm.cover_photo = response.data.file_path;
  //       })
  //       .catch((error) => {
  //         console.error("Error uploading cover photo:", error);
  //       });
  //   }, "image/jpeg");
  // },
    loadData() {
      axios.get(this.url).then((response) => {
        this.items = response.data.data;
      });
    },

    selectType(type) {
      this.type = type;
      this.createForm.type = type;
      this.$modal.hide('step-selection-modal');
      this.$modal.show('create-modal');
    },

    showCreateModal() {
      this.createForm = {
        position: '',
        title: '',
        description: '',
        photo: '',
        video: '',
        cover_photo: '',
        url: '',
        meta: {},
        errors: [],
        translations: {
          title: [],
          description: [],
          call_to_action: [],
        },
        type: '', // Reset banner type
      };
      this.$modal.show("step-selection-modal");
    },

    createBanner(e) {
      e.target.innerText = "Creating...";
      e.target.disabled = true;

      const payload = {
        position: this.createForm.position,
        title: this.createForm.title,
        description: this.createForm.description,
        meta: this.createForm.meta,
        photo: this.createForm.photo,
        video: this.createForm.video,
        cover_photo: this.createForm.cover_photo,
      };

      if (this.createForm.type === 'url') {
        payload.url = this.createForm.url;
        payload.call_to_action = this.createForm.call_to_action;
      } else if (this.createForm.type === 'product') {
        payload.product_id = this.createForm.product_id;
      }
      payload.type = this.createForm.type;


      axios
          .post(this.url, payload)
          .then((response) => {
            let translations = this.createForm.translations;
            if (Object.keys(translations.title).length != 0 || Object.keys(translations.description).length != 0) {
              this.updateBannerTranslations(response.data.data.id, translations,this.createForm)
                  .then(() => {
                    this.loadData();
                    this.$modal.hide("create-modal");
                  })
            }else {
              this.loadData();
              this.$modal.hide("create-modal");
            }
          })
          .catch((error) => {
            e.target.innerText = "Create";
            e.target.disabled = false;
            this.createForm.errors = Object.values(error.response.data.errors).flat();
          });
    },

    showUpdateModal(banner) {
      this.updateForm = {
        title: '',
        description: '',
        product: {},
        mediaUrl: '',
        photo: '',
        video: '',
        cover_photo: '',
        url: '',
        meta: {},
        errors: [],
        translations: {
          title: [],
          call_to_action: [],
          description: []
        },
        type: banner.url ? 'url' : 'product',
      };
      this.updateForm.id = banner.id;
      this.updateForm.title = banner.title;
      this.updateForm.description = banner.description;
      this.updateForm.meta = banner.meta;
      this.updateForm.mediaUrl = banner.media?.url;
      this.updateForm.mediaType = banner.media?.type;
      this.updateForm.url = banner.url || '';
      this.updateForm.call_to_action = banner.call_to_action || '';
      this.updateForm.type = banner.type;
      if (banner.type === 'product'){
        this.updateForm.product = {
          id: banner.product.id,
          title: banner.product.title,
          location: {city: {name: banner.product.city.name}}
        };
      }

      this.updateForm.translations = this.mapFromBannerTranslations(banner.translations);
      this.$emit("option:selected", this.updateForm.product);
      this.$modal.show("update-modal");
    },

    updateBanner(e) {
      e.target.innerText = "Editing...";
      e.target.disabled = true;

      const payload = {
        title: this.updateForm.title,
        description: this.updateForm.description,
        meta: this.updateForm.meta,
        photo: this.updateForm.photo,
        video: this.updateForm.video,
        cover_photo: this.updateForm.cover_photo,
        type: this.updateForm.type,
      };

      if (this.updateForm.type === 'url') {
        payload.url = this.updateForm.url;
        payload.call_to_action = this.updateForm.call_to_action;
      } else if (this.updateForm.type === 'product') {
        payload.product_id = this.updateForm.product_id;
      }

      axios
          .put(`${this.url}/${this.updateForm.id}`, payload)
          .then((response) => {
            this.$modal.hide("update-modal");
            let translations = this.updateForm.translations;
            if (Object.keys(translations.title).length != 0 || Object.keys(translations.description).length != 0) {
              this.updateBannerTranslations(response.data.data.id, translations,this.updateForm)
                  .then(() => {
                    this.loadData();
                  })
            }else {
              this.loadData();
            }
          })
          .catch((error) => {
            e.target.innerText = "Edit";
            e.target.disabled = false;
            this.updateForm.errors = Object.values(error.response.data.errors).flat();
          });
    },

    deleteBanner(banner) {
      if (confirm("Are you sure about this?!")) {
        axios
            .delete(`${this.url}/${banner.id}`)
            .then((response) => {
              this.loadData();
            })
            .catch((error) => {
              alert('Something went wrong!');
            });
      }
    },

    showPhotoModal(photoUrl) {
      this.photoModalCurrentUrl = photoUrl;
      this.$modal.show('photo-modal');
    },

    showVideoModal(videoUrl) {
      this.videoModalCurrentUrl = videoUrl;
      this.$modal.show('video-modal');
    },

    formatDate(date) {
      if (!date) return null;
      date = new Date(date);
      return (
          date.getFullYear() +
          "-" +
          (date.getMonth() + 1) +
          "-" +
          date.getDate() +
          " " +
          date.getHours() +
          ":" +
          date.getMinutes()
      );
    },

    fetchProducts(search, loading) {
      this.debouncedFetchProducts(search, loading);
    },

    setCreateSelected(value) {
      this.createForm.description = value?.short_description;
      this.createForm.title = value?.title;
      this.createForm.product_id = value?.id;
    },

    setUpdateSelected(value) {
      this.updateForm.description = value?.short_description;
      this.updateForm.title = value?.title;
      this.updateForm.product_id = value?.id;
    },

    updateBannerTranslations(bannerId, translations,data) {
      return axios.post(
          config("BACKEND_ROOT") + "/admin/translate",
          {
            "model": "banner",
            "model_id": bannerId,
            "translatable": this.mapToTranslations(translations,data)
          }
      ).then((response) => {
        this.successMessage = true;
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      })
          .catch((error) => {
            this.errors = Object.values(error.response.data.errors).flat();
          });
    },

    mapToTranslations(translations,data) {
      translations.title['en'] = data.title
      translations.description['en'] = data.description
      return Object.entries(translations)
          .flatMap(([key, values]) =>
              Object.entries(values).map(([language, value]) => ({
                'language': language,
                key,
                value,
              }))
          );
    },

    mapFromBannerTranslations(translations) {
      return translations.reduce((carry, translation) => {
        if (!carry[translation.key]) {
          carry[translation.key] = {};
        }
        carry[translation.key][translation.language_code] = translation.value;
        return carry;
      }, {});
    },

    getStatus(status) {
      if (status == true) {
        return '<span class="badge badge-success">Avilable</span>';
      } else {
        return '<span class="badge badge-secondary">Unavailable</span>';
      }
    },
  },
};
</script>


<style scoped>
.table {
  font-size: 14px !important;
}
.selection-container {
   display: flex;
   height: 200px;
   overflow: hidden;
   border-radius: 8px;
   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
 }

.selection-item {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.selection-item:hover {
  background-color: #e0e0e0; /* Light grey on hover */
  transform: scale(1.05);
}

.selection-item.product {
  background-color: #f5f5f5; /* Very light grey */
}

.selection-item.url {
  background-color: #dcdcdc; /* Light grey */
}

.selection-text {
  color: #333; /* Dark grey for text */
  font-size: 1.5em;
  text-align: center;
  z-index: 1; /* Ensure text is above any background */
}
</style>
